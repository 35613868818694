import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import every from 'lodash/every';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import omit from 'lodash/omit';
import defaults from 'lodash/defaults';
import ptNowLogo from 'assets/images/pt-now-logo.png';
import Panel from 'common/Panel';
import { Button } from '@keethealth/keet-ui';
import Success from './components/Success';
import Logo from './components/Logo';
import Question from './components/Question';

const isFinished = (items, answers) =>
  every(
    items,
    (questionnaireItem) =>
      (questionnaireItem.required
        ? get(answers, `[${questionnaireItem.linkId}].answer`)
        : true),
  );

const getSettings = (settings) => {
  if (isNil(settings.logoMarketing)) {
    return omit(settings, ['logoMarketing']);
  }
  return settings;
};

const NPSRenderer = (props) => {
  const {
    questionnaire,
    answers,
    onAnswer,
    onSubmit,
    loading,
    organization,
    isComplete,
  } = props;

  const questionnaireItems = get(questionnaire, 'fhir.item') || [];
  const isDisabled = loading || !isFinished(questionnaireItems, answers);
  const organizationName = get(organization, 'name');
  const source = getSettings(get(organization, 'settings'));
  const defaultSettings = getSettings(get(organization, 'account.defaultOrganization.settings'));
  const fallback = { logoMarketing: ptNowLogo };
  const settings = defaults(source, defaultSettings, fallback);

  return (
    <Panel
      id="scene-form"
      className="flex flex-column h-100 relative pt4 pb4"
    >
      <Logo src={settings.logoMarketing} alt={organizationName} />
      {isComplete ? (
        <Success />
      ) : (
        <Fragment>
          {questionnaireItems.map((item) => {
            const answer = get(answers, `[${item.linkId}].answer.value`);
            return (
              <Question
                key={item.linkId}
                question={item}
                answer={answer}
                onAnswer={onAnswer}
              />
            );
          })}
          <Button
            className="center mb5 mt3"
            onClick={
              isDisabled
                ? undefined
                : () => {
                  onSubmit(answers);
                }
            }
            disabled={isDisabled}
          >
            Submit
          </Button>
        </Fragment>
      )}
    </Panel>
  );
};

NPSRenderer.propTypes = {
  questionnaire: PropTypes.object,
  answers: PropTypes.objectOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      valueType: PropTypes.string,
    }),
  ),
  onAnswer: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  organization: PropTypes.shape({
    name: PropTypes.string,
    settings: PropTypes.shape({
      logoMarketing: PropTypes.string,
    }),
  }),
  isComplete: PropTypes.bool,
};

NPSRenderer.defaultProps = {
  loading: false,
  isComplete: false,
  questionnaire: {},
  answers: {},
  onAnswer: () => {},
  onSubmit: () => {},
  organization: {},
};

export default NPSRenderer;
