import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import { Loader } from '@keethealth/keet-ui';
import cN from 'classnames';

import queries from 'graph/queries';
import CURRENT_USER from 'graph/queries/currentUser';
import Panel from 'common/Panel';
import { getMessagingOtherParty } from 'utils/chat';
import Window from './Window';
import NewChat from './NewChat';

import '../messages.css';

export class Messages extends Component {
  static propTypes = {
    conversations: PropTypes.array,
    loadingConversations: PropTypes.bool.isRequired,
    userId: PropTypes.string,
  }

  static defaultProps = {
    conversations: [],
    userId: '',
  }


  state = {
    selectedConversationId: null,
    isStartingChat: false,
    mobileTab: 'selector',
  }

  componentDidUpdate() {
    const { conversations } = this.props;
    const { selectedConversationId } = this.state;
    if (!isEmpty(conversations) && isEmpty(selectedConversationId)) {
      this.setState({ selectedConversationId: head(conversations).id });
    }
  }

  handleSelectConversation = selectedConversationId => () => {
    this.setState({
      selectedConversationId,
      isStartingChat: false,
      mobileTab: 'messages',
    });
  }

  handleBack = () => {
    this.setState({
      mobileTab: 'selector',
    });
  }


  handleNew = () => {
    this.setState({
      isStartingChat: true,
      mobileTab: 'messages',
    });
  }

  renderWindow = () => {
    const { loadingConversations } = this.props;
    const { selectedConversationId, isStartingChat } = this.state;
    if (loadingConversations) {
      return <Loader color="primary" />;
    }
    if (isStartingChat) {
      return <NewChat onSelectConversation={this.handleSelectConversation} />;
    }
    if (selectedConversationId) {
      return (
        <Window
          conversationId={selectedConversationId}
        />
      );
    }
  }

  renderMessageSelector = (size) => {
    const {
      conversations,
      userId,
    } = this.props;
    const { selectedConversationId } = this.state;

    return (
      <ul id="messages-selector" className={`br ma0 pa0 overflow-auto ${size === 'desktop' && 'message-selector-desktop'}`}>
        {conversations.map((conversation) => {
          const otherParty = getMessagingOtherParty(userId, conversation);
          return (
            <div
              key={conversation.id}
              onClick={this.handleSelectConversation(conversation.id)}
              className={cN('selector-item flex items-center link pa3 pointer', { active: conversation.id === selectedConversationId })}
            >
              <div className="flex flex-column flex-auto overflow-hidden">
                <div className="flex justify-between flex-column">
                  <span>
                    {conversation.topic}
                  </span>
                  <span className="flex-shrink-0 f6 silver">
                    {`${otherParty.firstName} ${otherParty.lastName}`}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </ul>
    );
  }

  render() {
    const { mobileTab } = this.state;

    return (
      <Fragment>
        <Panel id="scene-messages" className="flex flex-column">
          <div className="f4 bb pa3 tc">
            {mobileTab === 'messages' &&
              <button className="fl primary bn pn pointer bg-inherit cursor dn-ns db" onClick={this.handleBack}>
                <i className="fas fa-2x fa-arrow-left" />
              </button>
            }
            <span>Messages</span>
            <button className="fr primary bn pn pointer bg-inherit" onClick={this.handleNew}>
              <i className="fas fa-2x fa-plus-circle" />
            </button>
          </div>
          <div className="dn flex-m flex-l flex-auto message-height">
            {this.renderMessageSelector('desktop')}
            {this.renderWindow()}
          </div>
          <div className="dn-ns db">
            {mobileTab === 'selector' && this.renderMessageSelector('mobile')}
            {mobileTab === 'messages' && this.renderWindow()}
          </div>
        </Panel>
      </Fragment>
    );
  }
}

export default compose(
  graphql(queries.conversations, {
    props: ({ data: { loading, conversations } }) => ({
      conversations,
      loadingConversations: loading,
    }),
  }),
  graphql(CURRENT_USER, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { currentUser } }) => ({
      userId: currentUser && currentUser.id,
    }),
  }),
)(Messages);
