import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class ChatSuggestion extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onCreate: PropTypes.func.isRequired,
  }

  render() {
    const { name, onCreate } = this.props;
    return (
      <div className="pa3 flex items-center pointer" onClick={onCreate}>
        <p className="f5">{name}</p>
        <p className="silver">{}</p>
      </div>
    );
  }
}
export default ChatSuggestion;
