import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import Textarea from 'react-textarea-autosize';

import queries from 'graph/queries';
import mutations from 'graph/mutations';
import '../messages.css';

class Input extends PureComponent {
  static defaultProps = {
    conversationId: '',
    practitionerId: '',
    isCreatingChat: false,
    onSelectConversation: () => {},
  }

  static propTypes = {
    conversationId: PropTypes.string,
    practitionerId: PropTypes.string,
    startConversation: PropTypes.func.isRequired,
    postMessage: PropTypes.func.isRequired,
    isCreatingChat: PropTypes.bool,
    onSelectConversation: PropTypes.func,
  }

  state = {
    value: '',
  }

  handleSubmit = () => {
    const { value } = this.state;
    const { postMessage, isCreatingChat, practitionerId, startConversation, onSelectConversation } = this.props;
    if (isCreatingChat) {
      startConversation({
        topic: value,
        receiverId: practitionerId,
      }).then((response) => {
        const conversationId = response.data.startConversation.conversation.id;
        onSelectConversation(conversationId)();
      });
    } else {
      postMessage({
        body: value,
      });
    }
    this.setState({
      value: '',
    });
  }

  handleChange = (event) => {
    this.setState({
      value: event.target.value,
    });
  }

  render() {
    const { value } = this.state;
    const { isCreatingChat } = this.props;
    return (
      <div id="window-input" className="flex flex-shrink-0 items-center w-100 bt">
        <Textarea
          className="flex-auto pa3 bn"
          placeholder={isCreatingChat ? 'Enter conversation topic' : 'Type a message...'}
          value={value}
          minRows={3}
          maxRows={9}
          onChange={this.handleChange}
        />
        <button
          className="bg-transparent bn h-100 w3 pointer"
          disabled={value.length === 0}
          onClick={this.handleSubmit}
        >
          <i className="fa fa-paper-plane fa-lg" aria-hidden="true" />
        </button>
      </div>
    );
  }
}

export default compose(
  graphql(mutations.startConversation, {
    props: ({ mutate }) => ({
      startConversation: ({ topic, receiverId }) => mutate({
        variables: { input: { topic, receiverId } },
        refetchQueries: [{
          query: queries.conversations,
        }],
      }),
    }),
  }),
  graphql(mutations.postMessage, {
    props: ({ mutate, ownProps: { conversationId } }) => ({
      postMessage: ({ body }) => mutate({
        variables: { input: {
          body,
          conversationId,
        } },
        refetchQueries: [{
          query: queries.conversation,
          variables: { id: conversationId },
        }],
      }),
    }),
  }),
)(Input);
