import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Loader } from '@keethealth/keet-ui';
import { parse, stringify } from 'qs';
import { logoutUser } from 'redux/auth/actions';
import Client from 'App/client';

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => {
    dispatch(logoutUser(false));
  },
});

class SignatureHandler extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        resourceId: PropTypes.string,
        signature: PropTypes.string,
      }),
    }).isRequired,
    path: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    onLogout: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        search: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    history: {},
    suffix: undefined,
  };

  state = {
    set: false,
  };

  componentDidMount() {
    this.resetState();
    this.setSignature();

    this.setState({ set: true });
  }

  setSignature = () => {
    const {
      match: {
        params: { resourceId, signature: token },
      },
    } = this.props;

    const { signature: signatureSearch } = this.getParams();
    const signature = token || signatureSearch;

    if (signature) {
      const accessToken = btoa(
        `${resourceId}:${decodeURIComponent(signature)}`
      );
      window.sessionStorage.setItem('@keethealth/signature', accessToken);
    }
  };

  getParams = () => {
    const { history } = this.props;

    return (
      parse(history.location.search, {
        ignoreQueryPrefix: true,
      }) || {}
    );
  };

  resetState = () => {
    const { onLogout } = this.props;
    onLogout();
    Client.clearStore();
  };

  render() {
    const { set } = this.state;
    const {
      path,
      suffix,
      match: {
        params: { resourceId },
      },
    } = this.props;

    const { signature, ...queryParams } = this.getParams();

    return set ? (
      <Redirect
        to={`/${path}/${resourceId}${suffix ? `/${suffix}` : ''}?${stringify(
          queryParams,
        )}`}
      />
    ) : (
      <Loader color="primary" />
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(SignatureHandler);
