import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import reject from 'lodash/reject';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import { carePlansIncomplete } from 'graph/queries/carePlans';
import { withRouter } from 'react-router-dom';

import { Button } from '@keethealth/keet-ui';
import CompletedModal from 'common/CompletedModal';
import mutations from 'graph/mutations';
import history from 'utils/history';
import { isQuestionEnabled, deflattenQuestionnaireAnswers } from 'utils/utils';
import Answers from './Answers';

class FormSummary extends Component {
  static propTypes = {
    createQuestionnaireResponse: PropTypes.func.isRequired,
    questionnaireId: PropTypes.string,
    onSetQuestion: PropTypes.func,
    handleMarkAsComplete: PropTypes.func,
    nextActivityId: PropTypes.string,
    questionsObject: PropTypes.objectOf(PropTypes.shape({
      index: PropTypes.number,
      readOnly: PropTypes.bool,
      repeats: PropTypes.bool,
      text: PropTypes.string,
      definition: PropTypes.string,
      type: PropTypes.oneOf(['integer', 'string', 'text', 'choice', 'display', 'group']),
      option: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        valueType: PropTypes.string,
      })),
      answer: PropTypes.oneOfType([
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          valueType: PropTypes.string,
        }),
        PropTypes.arrayOf(PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          type: PropTypes.string,
        })),
      ]),
    })).isRequired,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    questionnaireId: '',
    nextActivityId: '',
    readOnly: false,
    onSetQuestion: () => {},
    handleMarkAsComplete: () => {},
  }

  state = {
    isModalOpen: false,
    isSubmitDisabled: false,
  }

  handleSubmit = () => {
    this.setState({ isSubmitDisabled: true });
    const { questionnaireId, createQuestionnaireResponse, questionsObject } = this.props;

    const enabledItems = filter(questionsObject, question =>
      isQuestionEnabled(questionsObject, question.linkId));
    const items = deflattenQuestionnaireAnswers(enabledItems);

    createQuestionnaireResponse({ questionnaireId, items }).then(() => {
      const { nextActivityId } = this.props;
      this.props.handleMarkAsComplete();
      if (isEmpty(nextActivityId)) {
        this.setState({
          isModalOpen: true,
        });
      } else {
        this.handleEnd();
      }
    });
  }

  handleEnd = () => {
    const { nextActivityId } = this.props;
    if (isEmpty(nextActivityId)) {
      history.push('/my_tasks');
    } else {
      history.push(`/my_tasks/${this.props.nextActivityId}`);
    }
  }

  renderAnswers = () => {
    const { questionsObject, onSetQuestion, readOnly } = this.props;
    const questionAnswers = [];
    const nonGroupItems = reject(questionsObject, { type: 'group' });
    const enabledItems = filter(questionsObject, question =>
      isQuestionEnabled(questionsObject, question.linkId));
    forEach(enabledItems, (question) => {
      if (question.answer || question.answer === '') {
        const answerProps = {
          key: question.linkId,
          readOnly,
          onSetQuestion,
          questionIndex: findIndex(nonGroupItems, { linkId: question.linkId }),
          text: question.text,
          answer: question.answer,
          repeats: question.repeats,
          option: question.answerOption,
          isAnswerType: true,
          onComplete: () => {},
          onAnswer: () => {},
          disabled: true,
        };

        switch (question.type) {
          case 'choice':
            questionAnswers.push(
              <Answers.Choice {...answerProps} />,
            );
            break;
          case 'integer':
            questionAnswers.push(
              <Answers.Slider {...answerProps} />,
            );
            break;
          case 'string':
          case 'text':
            questionAnswers.push(
              <Answers.Text {...answerProps} />,
            );
            break;
          case 'date':
            questionAnswers.push(
              <Answers.Date {...answerProps} />,
            );
            break;
          default:
            break;
        }
      }
    });
    return questionAnswers;
  }

  render() {
    const { readOnly } = this.props;
    const { isModalOpen, isSubmitDisabled } = this.state;
    return (
      <div id="form-summary" className="flex-auto w-100">
        {this.renderAnswers()}
        {!readOnly && (
          <Button
            className="center ma3"
            onClick={this.handleSubmit}
            disabled={isSubmitDisabled}
          >
            SUBMIT
            <i className="fas fa-paper-plane ml2" />
          </Button>
        )}
        <CompletedModal isOpen={isModalOpen} onClose={this.handleEnd} />
      </div>
    );
  }
}

export default compose(
  withRouter,
  graphql(mutations.createQuestionnaireResponse, {
    props: ({ mutate, ownProps: { location } }) => {
      const isQRTask = location && location.pathname.includes('/qr_task');
      return ({
        createQuestionnaireResponse: ({ questionnaireId, items }) => mutate({
          variables: { input: { questionnaireId, items } },
          refetchQueries: isQRTask
            ? []
            : [{
              query: carePlansIncomplete,
            }],
        }),
      });
    },
  }),
)(FormSummary);
