import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { withRouter, Switch } from 'react-router-dom';
import CustomProperties from 'react-custom-properties';
import NotificationContainer from 'common/NotificationContainer';
import ErrorBoundary from 'common/ErrorBoundary';
import routes from './routes';
import AuthRoute from './AuthRoute';

class AppBody extends Component {
  state = {
    errorBoundaryKey: 0,
  };

  handleChangeErrorKey = () =>
    this.setState(prevState => ({
      errorBoundaryKey: prevState.errorBoundaryKey + 1,
    }));

  render() {
    const { errorBoundaryKey } = this.state;
    return (
      <CustomProperties
        global
        properties={{
          '--theme-primary-color': '#485cc7',
          '--theme-secondary-color': '#05c3de',
          '--theme-tertiary-color': '#9578d3',
          '--theme-loader-color': '#B3BDF5',
        }}
      >
        <ErrorBoundary key={errorBoundaryKey} onChangeErrorKey={this.handleChangeErrorKey}>
          <Switch>
            {routes.map(route => (
              <AuthRoute
                isPublic={route.isPublic}
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
                render={route.render}
              />
            ))}
          </Switch>
          <NotificationContainer />
        </ErrorBoundary>
      </CustomProperties>
    );
  }
}

export default compose(
  withRouter,
)(AppBody);
