import SingleChoice from './SingleChoice';
import MultiChoice from './MultiChoice';
import Display from './Display';
import Text from './Text';
import Slider from './Slider';
import String from './String';
import Date from './Date';

export default {
  SingleChoice,
  MultiChoice,
  Display,
  Text,
  Slider,
  String,
  Date,
};
