import React from 'react';

import './ContentCardFooter.css';

export default function ContentCardFooter({ children }) {
  return (
    <div className='content-card-footer'>
      {children}
    </div>
  )
}
