import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { Alert, Form } from 'antd';
import head from 'lodash/head';
import { Link } from 'react-router-dom';
import { Button } from '@keethealth/keet-ui';
import { validateDate, validatePhone } from 'utils/formValidators';

import LoginPanel from 'common/LoginPanel';
import { loginUser } from 'redux/auth/actions';
import ptNowLogo from 'assets/images/pt-now-logo.png';
import headerBG from 'assets/images/Product-Header-BG.png';
import { getNotifications } from 'redux/notification';
import { validateRegistrationCode, registerPatient } from 'services/api';
import LoginFormItem from './LoginFormItem';
import './login.css';

const mapDispatchToProps = dispatch => (
  {
    onLoginUser: () => { dispatch(loginUser()); },
  }
);

const mapStateToProps = state => ({
  notifications: getNotifications(state),
});

const bgStyle = { backgroundImage: `url(${headerBG})` };

export class Login extends Component {
  static propTypes = {
    onLoginUser: PropTypes.func.isRequired,
    notifications: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
  }

  static defaultProps = {
    notifications: {},
  }

  state = {
    validatedCode: false,
    registered: false,
  }

  handleSubmitPatient = (event) => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          await registerPatient(values);
          this.setState({ registered: true });
        } catch (e) {
          const { response } = e;
          const { form } = this.props;
          if (response.errors) {
            response.errors.forEach((res) => {
              const { id, detail } = res;
              form.setFields({
                [id]: {
                  value: values[id],
                  errors: [new Error(detail)],
                },
              });
            });
          }
        }
      }
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          const response = await validateRegistrationCode(values);
          this.setState({ validatedCode: response }, () => {
            const { data: { attributes } } = response;
            this.props.form.setFieldsValue({
              organization_id: attributes.organization_id,
              registration_code: attributes.code,
            });
          });
        } catch (e) {
          const { form } = this.props;
          const error = head(e.response.errors);
          form.setFields({
            code: {
              value: values.code,
              errors: [new Error(error.detail)],
            },
          });
        }
      }
    });
  }

  renderPatientFormItems = () => {
    const { form: { getFieldDecorator } } = this.props;
    const formItems = [
      {
        label: 'First Name',
        name: 'given',
        rules: [
          {
            required: true,
            message: 'Please enter a first name',
          },
        ],
        initialValue: 'hello',
        placeholder: 'e.g. Jen',
      },
      {
        label: 'Last Name',
        name: 'family',
        rules: [
          {
            required: true,
            message: 'Please enter a last name',
          },
        ],
        placeholder: 'e.g. Johnson',
      },
      {
        label: 'Email',
        name: 'email',
        rules: [
          {
            required: true,
            message: 'Please enter an email',
          },
          {
            type: 'email',
            message: 'Please enter a valid email',
          },
        ],
      },
      {
        label: 'Username',
        name: 'username',
        rules: [
          {
            required: true,
            message: 'Please enter a username',
          },
        ],
      },
      {
        label: 'Phone Number',
        name: 'phone',
        type: 'phone',
        rules: [
          {
            required: true,
            message: 'Please select a phone number',
          },
          { validator: validatePhone },
        ],
      },
      {
        label: 'Gender',
        name: 'gender',
        type: 'gender',
        rules: [
          {
            required: true,
            message: 'Please select a gender',
          },
        ],
      },
      {
        label: 'Birthdate',
        name: 'birthdate',
        type: 'date',
        rules: [
          {
            required: true,
            message: 'Please enter a birthdate',
          },
          { validator: validateDate },
        ],
      },
      {
        label: 'Postal Code',
        name: 'address_postalcode',
        rules: [
          {
            required: true,
            message: 'Please enter a postal code',
          },
        ],
      },
      {
        label: 'Organization',
        name: 'organization_id',
        disabled: true,
        style: { display: 'none' },
      },
      {
        label: 'Code',
        name: 'registration_code',
        disabled: true,
        style: { display: 'none' },
      },
    ];
    return formItems.map((itemProps, index) => (
      <LoginFormItem
        {...itemProps}
        key={itemProps.name}
        getFieldDecorator={getFieldDecorator}
        autoFocus={index === 0}
      />
    ));
  }

  renderFormItems = () => {
    const { form: { getFieldDecorator } } = this.props;
    const formItems = [
      {
        label: 'Code',
        name: 'code',
      },
    ];
    return formItems.map((itemProps, index) => (
      <LoginFormItem
        {...itemProps}
        key={itemProps.name}
        getFieldDecorator={getFieldDecorator}
        autoFocus={index === 0}
      />
    ));
  }

  renderMessage = () =>
    <Alert className="alert" message="Registration successful! We've sent you a link to confirm your email and set your password." type="success" showIcon />;

  render() {
    const { validatedCode, registered } = this.state;

    let Body;
    if (validatedCode) {
      Body = (
        <Form onSubmit={this.handleSubmitPatient} className="registration-form" >
          {this.renderPatientFormItems()}
          <Button className="mt4 center w-100 loginButton br2 f4 fw4 mb4" type="submit">Submit</Button>
        </Form>
      );
    } else {
      Body = (
        <Form onSubmit={this.handleSubmit}>
          {this.renderFormItems()}
          <Button className="mt4 center w-100 loginButton br2 f4 fw4 mb4" type="submit">Continue</Button>
        </Form>
      );
    }

    return (
      <div className="flex w-100 h-100 cover bg-center cover items-center min-vh-100 z-1 overflow-x-auto" style={bgStyle}>
        <LoginPanel className="w-100 panel flex flex-column justify-center items-center pa4 br2">
          <img src={ptNowLogo} alt="Pt Now Logo" className="pt-now-logo" />
          <h1 className="index_title fw5 mt4 mb3 tc">Patient Registration</h1>
          {
            registered
              ? this.renderMessage()
              : Body
          }
          <Link to="/login" className="pointer registration-link w5 login-screen tc">
            Back to login
          </Link>
        </LoginPanel>
      </div>
    );
  }
}

export default compose(
  Form.create(),
  connect(mapStateToProps, mapDispatchToProps),
)(Login);
