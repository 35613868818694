import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';

import CURRENT_PROFILE from 'graph/queries/currentUser';
import Input from './Input';
import WindowTitle from './WindowTitle';

export class CreateChat extends PureComponent {
  static defaultProps = {
    patientId: '',
  }

  static propTypes = {
    practitioner: PropTypes.object.isRequired,
    patientId: PropTypes.string,
    onSelectConversation: PropTypes.func.isRequired,
  }

  render() {
    const { practitioner: { id, given, family }, patientId, onSelectConversation } = this.props;
    return (
      <div className="flex flex-column flex-auto">
        <WindowTitle
          name={`Create Conversation with ${given} ${family}`}
        />
        <div className="flex-auto" />
        <Input
          isCreatingChat
          practitionerId={id}
          patientId={patientId}
          onSelectConversation={onSelectConversation}
        />
      </div>
    );
  }
}

export default compose(
  graphql(CURRENT_PROFILE, {
    props: ({ data: { currentUser } }) => ({
      patientId: currentUser.id,
    }),
  }),
)(CreateChat);
