import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PRACTITIONERS from 'graph/queries/practitioners';
import { graphql, compose } from 'react-apollo';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import ChatSuggestion from './ChatSuggestion';

class ChatSuggestions extends Component {
  static propTypes = {
    practitioners: PropTypes.shape({
      id: PropTypes.string,
      given: PropTypes.string,
      family: PropTypes.string,
    }),
    onBeginCreation: PropTypes.func.isRequired,
  }

  static defaultProps = {
    practitioners: [],
  }

  renderPractitioner = (practitioner) => {
    const { onBeginCreation } = this.props;
    return (
      <ChatSuggestion
        key={practitioner.id}
        name={`${practitioner.family}, ${practitioner.given}`}
        onCreate={onBeginCreation(practitioner)}
      />
    );
  };

  renderSuggestions = () => {
    const { practitioners } = this.props;
    return map(practitioners, this.renderPractitioner);
  }

  render() {
    return (
      <div className="h-100 message-scroll">
        {this.renderSuggestions()}
      </div>
    );
  }
}

export default compose(
  graphql(PRACTITIONERS, {
    props: ({ data: { practitioners } }) => ({
      practitioners,
    }),
    options: ({ searchQuery }) => ({
      variables: {
        search: isEmpty(searchQuery) ? null : searchQuery,
      },
    }),
  }),
)(ChatSuggestions);
