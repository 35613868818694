import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import get from 'lodash/get';
import reject from 'lodash/reject';
import update from 'immutability-helper';
import { SizeMe } from 'react-sizeme';
import { withRouter, Prompt } from 'react-router-dom';

import queries from 'graph/queries';

import { Button, Carousel, CarouselArrow, CarouselDots } from '@keethealth/keet-ui';
import Panel from 'common/Panel';
import { getQuestionsObject, optionToValue } from 'utils/form';
import { checkHasStarted, isQuestionEnabled, isQuestionnaireFinished, flattenQuestionnaireItems } from 'utils/utils';
import FormQuestion from './FormQuestion';
import FormSummary from './FormSummary';
import Questions from './Questions';
import '../Questionnaire.css';

const isInQuestionnaire = (pathname, taskId) => (
  pathname.startsWith(`/my_tasks/${taskId}`) || pathname.startsWith(`/qr_tasks/${taskId}`)
);

export class Questionnaire extends Component {
  static propTypes = {
    getNextActivity: PropTypes.func.isRequired,
    questionnaire: PropTypes.shape({
      plannable: PropTypes.shape({
        fhir: PropTypes.shape({
          identifier: PropTypes.arrayOf(PropTypes.object),
          name: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          status: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          purpose: PropTypes.string.isRequired,
          item: PropTypes.arrayOf(PropTypes.shape({
            linkId: PropTypes.string.isRequired,
            definition: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['integer', 'string', 'text', 'choice', 'display', 'group']),
            readOnly: PropTypes.bool.isRequired,
            repeats: PropTypes.bool.isRequired,
            required: PropTypes.bool.isRequired,
            option: PropTypes.arrayOf(PropTypes.shape({
              valueInteger: PropTypes.string,
              valueString: PropTypes.string,
              extension: PropTypes.arrayOf(PropTypes.shape({
                uri: PropTypes.string,
                valueDecimal: PropTypes.string,
              })),
            })),
            enableWhen: PropTypes.arrayOf(PropTypes.shape({
              question: PropTypes.string,
              hasAnswer: PropTypes.bool,
              answerInteger: PropTypes.number,
              answerString: PropTypes.string,
            })),
          })),
        }),
      }),
    }),
    loading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        taskId: PropTypes.string,
        questionIndex: PropTypes.string,
      }),
    }).isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        state: PropTypes.object,
      }),
    }).isRequired,
  }

  static defaultProps = {
    questionnaire: {},
  }

  static getDerivedStateFromProps(props, state) {
    const {
      questionnaire,
      loading,
    } = props;
    const { questionsCompleted, questionsObject } = state;

    if (!loading && questionnaire && (questionsCompleted.length === 0 || Object.keys(questionsObject).length === 0)) {
      const flattenedItems = get(questionnaire, 'plannable.fhir.item');
      if (flattenedItems) {
        const nonGroupItems = reject(flattenedItems, { type: 'group' });
        return {
          ...state,
          questionsCompleted: Array(nonGroupItems.length).fill(false),
          questionsObject: getQuestionsObject(flattenedItems),
        };
      }
      return {
        ...state,
      };
    }
    return {
      ...state,
    };
  }

  state = {
    questionsCompleted: [],
    questionsObject: {},
    isModalOpen: false,
    scrolled: false,
    isComplete: false,
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('beforeunload', this.handleAlertBeforeClose);
  };

  getCurrentIndex = () => {
    const {
      match: { params: { questionIndex } },
    } = this.props;
    return questionIndex ? parseInt(questionIndex, 10) : undefined;
  }

  getBasePath = () => {
    try {
      const {
        match: { path },
      } = this.props;
      const pathRegex = /\/(.+)\/:taskId.*/;
      return path.match(pathRegex)[1];
    } catch (e) {
      return 'my_tasks';
    }
  };

  getPrevIndex = () => {
    const {
      history: { location: { state: { prevIndex } } },
    } = this.props;
    return prevIndex ? parseInt(prevIndex, 10) : undefined;
  }

  handleAlertBeforeClose = (ev) => {
    ev.preventDefault();
    return ev.returnValue = 'Changes you made may not be saved.';
  }

  handleMoveToQuestion = (targetIndex, useReplace = false) => {
    const {
      history,
      match: { params: { taskId, questionIndex } },
    } = this.props;
    const newUrl = `/${this.getBasePath()}/${taskId}/${targetIndex}`;

    if (useReplace) {
      history.replace(newUrl, { prevIndex: questionIndex });
    } else {
      history.push(newUrl, { prevIndex: questionIndex });
    }
  }

  handleNext = (useReplace = false) => {
    const { questionsCompleted } = this.state;
    const lastIndex = questionsCompleted.length - 1;
    const questionIndex = this.getCurrentIndex();
    if (questionIndex < lastIndex) {
      this.handleMoveToQuestion(questionIndex + 1, useReplace);
    } else {
      this.handleViewSummary();
    }
    this.setState({
      scrolled: false,
    });
  }

  handlePrev = () => {
    const questionIndex = this.getCurrentIndex();
    if (questionIndex > 0) {
      this.handleMoveToQuestion(questionIndex - 1);
    }
    this.setState({
      scrolled: false,
    });
  }

  handleSetQuestion = (questionIndex) => {
    const questionIndexInt = parseInt(questionIndex, 10);
    this.handleMoveToQuestion(questionIndexInt);
  }

  handleComplete = () => {
    const questionIndex = this.getCurrentIndex();
    const { questionsCompleted } = this.state;
    this.setState({
      questionsCompleted: update(questionsCompleted, {
        [questionIndex]: { $set: true },
      }),
    });
  }

  handleIncomplete = () => {
    const questionIndex = this.getCurrentIndex();
    const { questionsCompleted } = this.state;
    this.setState({
      questionsCompleted: update(questionsCompleted, {
        [questionIndex]: { $set: false },
      }),
    });
  }

  handleAnswer = (questionId, questionAnswer) => {
    const { questionsObject } = this.state;
    this.setState({
      questionsObject: update(questionsObject, {
        [questionId]: { answer: { $set: questionAnswer } },
      }),
    });
  }

  handleViewSummary = () => {
    this.handleMoveToQuestion('summary');
  }

  handleScroll = () => {
    this.setState({
      scrolled: true,
    });
  };

  handleMarkAsComplete = () => {
    this.setState({
      isComplete: true,
    });
  }

  handleSkip = () => {
    if (this.getCurrentIndex() > this.getPrevIndex()) {
      this.handleNext(true);
    } else {
      this.handlePrev(true);
    }
  }

  renderQuestion = (question) => {
    const { questionsObject } = this.state;
    if (questionsObject[question.linkId]) {
      const answer = questionsObject[question.linkId].answer || undefined;

      const questionProps = {
        key: question.linkId,
        text: question.text,
        id: question.linkId,
        required: question.required,
        onComplete: this.handleComplete,
        onIncomplete: this.handleIncomplete,
        onAnswer: this.handleAnswer,
        onNext: this.handleNext,
        answer,
      };
      // TODO: Once 'feature/questionnaire-versioning' branch is merged, remove this handling and just use 'question.answerOption' directly.
      const options = Object.hasOwn(question, 'answerOption') ?  question.answerOption : question.option;
      switch (question.type) {
        case 'integer':
          return (
            <Questions.Slider {...questionProps} />
          );
        case 'string':
          return (
            <Questions.String {...questionProps} />
          );
        case 'text':
          return (
            <Questions.Text {...questionProps} />
          );
        case 'choice':
          return question.repeats
            ? <Questions.MultiChoice {...questionProps} option={options.map(optionToValue)} />
            : <Questions.SingleChoice {...questionProps} option={options.map(optionToValue)} />;
        case 'display':
          return (
            <Questions.Display {...questionProps} />
          );
        case 'date':
          return (
            <Questions.Date {...questionProps} />
          );
        default:
          break;
      }
    }
    return <noscript key="noscript" />;
  }

  renderQuestions = () => {
    const {
      questionnaire,
    } = this.props;
    const { questionsCompleted } = this.state;
    const questionIndex = this.getCurrentIndex();

    if (typeof questionIndex === 'undefined' || Math.sign(questionIndex) === -1) {
      this.handleMoveToQuestion(0, true);
    } else if (questionnaire) {
      const flattenedItems = get(questionnaire, 'plannable.fhir.item');
      if (flattenedItems) {
        if (questionIndex > questionsCompleted.length) {
          this.handleMoveToQuestion(0, true);
        }
        const nonGroupItems = reject(flattenedItems, { type: 'group' });
        return nonGroupItems.map(question => this.renderQuestion(question));
      }
      this.handleViewSummary();
    }
    return <noscript />;
  }

  renderButtons = () => {
    const { questionsCompleted } = this.state;
    const questionIndex = this.getCurrentIndex();
    const completed = questionsCompleted[questionIndex];
    return (
      <Fragment>
        <div className="w3 mh1">
          <CarouselArrow
            direction="left"
            hidden={questionIndex === 0}
            onClick={this.handlePrev}
          />
        </div>
        <div className="w3 mh1">
          {completed &&
            <CarouselArrow
              direction="right"
              hidden={questionIndex === questionsCompleted.length - 1 || questionsCompleted.length === 0}
              onClick={this.handleNext}
            />
          }
        </div>
      </Fragment>
    );
  }

  renderScrollIndicator = (size) => {
    const { scrolled } = this.state;
    return (
      <Fragment>
        {size.height + 60 > document.documentElement.clientHeight && !scrolled &&
          <div className="flex justify-center center-align">
            <div className="absolute tc bg-white pa3 w-100 flex items-center justify-center scroll-indicator" style={{ bottom: (size.height + 77) - document.documentElement.clientHeight }}>
              <span>Scroll </span>
              <div className="br-100 h2 w2 flex justify-center items-center ml1">
                <i className="fas fa-chevron-down" />
              </div>
            </div>
          </div>
        }
      </Fragment>
    );
  }

  render() {
    const {
      questionnaire,
      loading,
      match: { params: { taskId, questionIndex } },
      getNextActivity,
    } = this.props;
    const questionIndexInt = this.getCurrentIndex();

    let title = 'Questionnaire';
    if (loading) return null;

    if (taskId && questionnaire) {
      title = questionnaire.plannable.fhir.title;
    }
    const {
      questionsCompleted,
      questionsObject,
      isComplete,
    } = this.state;

    const isFinished = isQuestionnaireFinished(questionsObject, questionsCompleted);

    const hasStarted = checkHasStarted(questionIndex, questionsCompleted, questionsObject);

    if (hasStarted) {
      window.addEventListener('beforeunload', this.handleAlertBeforeClose);
    } else {
      window.removeEventListener('beforeunload', this.handleAlertBeforeClose);
    }

    const flattenedItems = get(questionnaire, 'plannable.fhir.item');
    const nonGroupItems = reject(flattenedItems, { type: 'group' });
    const currentQuestion = get(nonGroupItems, `[${questionIndexInt}]`);

    if (currentQuestion && !isQuestionEnabled(questionsObject, currentQuestion.linkId)) {
      this.handleSkip();
    }

    return (
      <Fragment>
        <Prompt
          message={params => (!isInQuestionnaire(params.pathname, taskId) && hasStarted && !isComplete
            ? 'Do you want to leave? Changes you made will not be saved.' : true)}
        />
        <SizeMe monitorHeight>
          {({ size }) => (
            <Panel id="scene-form" className="flex flex-column h-100 relative">
              {questionIndex === 'summary'
                ? (
                  <FormQuestion title={title}>
                    <FormSummary
                      questionnaireId={taskId}
                      onSetQuestion={this.handleSetQuestion}
                      questionsObject={questionsObject}
                      nextActivityId={getNextActivity()}
                      handleMarkAsComplete={this.handleMarkAsComplete}
                    />
                  </FormQuestion>
                )
                : (
                  <FormQuestion title={title}>
                    <CarouselDots
                      length={questionsCompleted.length}
                      completedSlides={questionsCompleted}
                      currentSlide={questionIndexInt}
                    />
                    <Carousel
                      className="w-100 flex-auto flex flex-column justify-center pb5"
                      controlled
                      currentIndex={questionIndexInt}
                    >
                      {this.renderQuestions()}
                    </Carousel>
                    <div className="dn dn-m db-l w-100 absolute">{this.renderButtons()}</div>
                    <div className="flex justify-center dn-l mb3">{this.renderButtons()}</div>
                    {isFinished &&
                      <Button
                        className="center mb5 mt3"
                        onClick={this.handleViewSummary}
                      >
                        VIEW SUMMARY
                        <i className="fas fa-list-ol ml2" />
                      </Button>
                    }
                  </FormQuestion>
                )
              }
              {this.renderScrollIndicator(size)}
            </Panel>
          )}
        </SizeMe>
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  graphql(queries.questionnaire, {
    props: ({ data: { loading, questionnaire } }) => {
      if (questionnaire) {
        const fhir = JSON.parse(questionnaire.plannable.fhir);
        return {
          loading,
          questionnaire: {
            ...questionnaire,
            plannable: {
              ...questionnaire.plannable,
              fhir: {
                ...fhir,
                item: flattenQuestionnaireItems(fhir.item),
              },
            },
          },
        };
      }
      return {
        loading,
        questionnaire,
      };
    },
    options: ({ match: { params: { taskId: id } } }) => ({
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
)(Questionnaire);
