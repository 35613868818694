import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class SelectorSearch extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  render() {
    const { value, onChange } = this.props;
    return (
      <div className="message-search bb flex items-center pa3 silver">
        <i className="fa fa-search" aria-hidden="true" />
        <input
          placeholder="Search for a Therapist"
          className="ml3 bn flex-auto"
          value={value}
          onChange={onChange}
        />
      </div>
    );
  }
}

export default SelectorSearch;
