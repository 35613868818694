import React, { Component, Fragment } from 'react';
import { HeaderTab } from '@keethealth/keet-ui';
import PropTypes from 'prop-types';
import ptNowLogo from 'assets/images/pt-now-header-logo.png';
import HeaderProfile from './HeaderProfile';
import HeaderMobile from './HeaderMobile';
import MessageBadge from './MessageBadge';
import '../header.css';

export class Header extends Component {
  static propTypes = {
    carePlans: PropTypes.arrayOf(
      PropTypes.shape({
        carePlanActivities: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            required: PropTypes.bool,
          }),
        ),
      }),
    ).isRequired,
  }

  static defaultProps = {
    carePlans: [
      {
        id: '',
      },
    ],
    patientRecord: {},
  }

  renderTabs = () => {
    const menu = [
      {
        key: 'my_tasks',
        to: '/my_tasks',
        title: 'My Tasks',
      },
      {
        key: 'progress',
        to: '/progress',
        title: 'Progress',
      },
      {
        key: 'history',
        to: '/history',
        title: 'History',
      },
      {
        key: 'messages',
        to: '/messages',
        title: (
          <MessageBadge>
            Messages
          </MessageBadge>
        ),
      },
    ];
    return menu.map(props => (
      <HeaderTab {...props} />
    ));
  }

  render() {
    return (
      <Fragment>
        <header id="keet-header" className="bg-white bb fw3 f5">
          <div className="mw8 w-100 center flex flex-shrink-0 items-center content-between">
            <div className="flex w-100 items-center justify-center">
              <div className="flex flex-auto pl3">
                <img id="header-logo" src={ptNowLogo} alt="keet-logo" />
              </div>
              <div className="dn db-l">
                <div className="flex">
                  <div className="br flex fw4">
                    {this.renderTabs()}
                  </div>
                  <HeaderProfile />
                </div>
              </div>
            </div>
            <div className="w-100 db dn-ns db-m dn-l">
              <HeaderMobile />
            </div>
          </div>
        </header>
      </Fragment>
    );
  }
}

export default Header;
