import Choice from './Choice';
import Slider from './Slider';
import Text from './Text';
import Date from './Date';

export default {
  Choice,
  Text,
  Slider,
  Date,
};
