import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';

import queries from 'graph/queries';
import CURRENT_USER from 'graph/queries/currentUser';
import mutations from 'graph/mutations';
import { getMessagingOtherParty } from 'utils/chat';
import { Loader } from '@keethealth/keet-ui';
import Chat from './Chat';
import Input from './Input';
import WindowTitle from './WindowTitle';

class Window extends Component {
  static propTypes = {
    conversation: PropTypes.object,
    userId: PropTypes.string,
    readMessage: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    conversation: {
      id: '',
      messages: [],
      sender: {},
      receiver: {},
    },
    userId: '',
    loading: false,
  }

  componentDidUpdate() {
    this.readMessages();
  }

  readMessages = () => {
    const { readMessage, userId, conversation: { messages } } = this.props;
    forEach(messages, (message) => {
      if (message.createdBy !== userId && isEmpty(message.readAt)) {
        readMessage({ id: message.id });
      }
    });
  }

  render() {
    const { loading, conversation, userId } = this.props;
    const otherParty = getMessagingOtherParty(userId, conversation);
    return (
      <div className="messages-window flex flex-auto flex-column tc">
        <WindowTitle
          name={conversation.topic || ''}
          subtitle={loading ? '' : `${otherParty.firstName} ${otherParty.lastName}`}
        />
        {loading && <Loader color="primary" />}
        {conversation &&
          <Chat
            patientId={userId}
            messages={conversation.messages}
          />
        }
        {conversation &&
          <Input conversationId={conversation.id} />
        }
      </div>
    );
  }
}

export default compose(
  graphql(mutations.readMessage, {
    props: ({ mutate }) => ({
      readMessage: ({ id }) => mutate({
        variables: { input: { id } },
      }),
    }),
  }),
  graphql(queries.conversation, {
    props: ({ data: { loading, conversation }, ownProps }, lastProps) => ({
      conversation,
      loading: loading && (lastProps && lastProps.conversation ? ownProps.conversationId !== lastProps.conversation.id : true),
    }),
    options: ({ conversationId }) => ({
      variables: { id: conversationId },
      pollInterval: 10000,
    }),
  }),
  graphql(CURRENT_USER, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { currentUser } }) => ({
      userId: currentUser && currentUser.id,
    }),
  }),
)(Window);
