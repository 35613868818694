import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Empty } from 'antd';

import ChatItem from './ChatItem';

class Chat extends Component {
  static propTypes = {
    messages: PropTypes.array.isRequired,
    patientId: PropTypes.string.isRequired,
  }

  renderItems = () => {
    const { messages, patientId } = this.props;
    if (isEmpty(messages)) {
      return (
        <Empty
          className="flex flex-column justify-center items-center flex-auto"
          description={
            <span>
              This conversation has no secure messages.
            </span>
          }
        />
      );
    }
    return messages.map(message => (
      <ChatItem
        key={message.id}
        isSender={message.createdBy === patientId}
        text={message.body}
        createdAt={message.createdAt}
        readAt={message.readAt}
      />
    ));
  }

  render() {
    return (
      <Fragment>
        <ul id="window-chat" className="dn-ns flex flex-auto flex-column-reverse overflow-auto pa3 ma0 mobile-window-chat">
          {this.renderItems()}
        </ul>
        <ul id="window-chat" className="dn flex-m flex-l flex-auto flex-column-reverse overflow-auto pa3 ma0 desktop-window-chat">
          {this.renderItems()}
        </ul>
      </Fragment>
    );
  }
}

export default Chat;
