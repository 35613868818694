import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ChatSuggestions from './ChatSuggestions';
import CreateChat from './CreateChat';
import SelectorSearch from './SelectorSearch';
import '../messages.css';

export class NewChat extends PureComponent {
  static propTypes = {
    onSelectConversation: PropTypes.func.isRequired,
  }

  state = {
    newChatTherapist: null,
    searchQuery: '',
  }

  handleBeginCreation = practitioner => () => {
    this.setState({
      newChatTherapist: practitioner,
    });
  }

  handleChange = (event) => {
    const { target: { value } } = event;
    this.setState({
      searchQuery: value,
    });
  }

  render() {
    const { newChatTherapist, searchQuery } = this.state;
    const { onSelectConversation } = this.props;
    return (
      <div className="messages-window flex flex-column flex-auto">
        {!newChatTherapist &&
          <SelectorSearch value={searchQuery} onChange={this.handleChange} />
        }
        {newChatTherapist
          ? (
            <CreateChat onSelectConversation={onSelectConversation} practitioner={newChatTherapist} />
          )
          : (
            <ChatSuggestions searchQuery={searchQuery} onBeginCreation={this.handleBeginCreation} />
          )
        }
      </div>
    );
  }
}

export default NewChat;
