import React from 'react';

const EducationIcon = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 49 48" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M5 0h40a4 4 0 0 1 4 4v40a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
      />
      <path
        d="M45 0H5a4 4 0 0 0-4 4v39a4 4 0 0 0 4 4h40a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z"
      />
      <path
        d="M44 1H4a4 4 0 0 0-4 4v39a4 4 0 0 0 4 4h40a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4z"
      />
      <path
        d="M17.022 20.486H17v12c.913-1.209 2.37-2 4-2 1.129 0 2.161.396 3 1.031V19.53c-.835-.643-1.863-1.044-3-1.044-1.63 0-3.064.792-3.978 2zm15.957 0c-.914-1.208-2.348-2-3.979-2-1.137 0-2.164.401-3 1.044v12c.836-.641 1.863-1.043 3-1.043a5.02 5.02 0 0 1 4 2V26.46v-5.972l-.021-.002z"
        stroke="#9578d3"
      />
    </g>
  </svg>
);

export default EducationIcon;
