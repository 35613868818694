import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';
import moment from 'moment';

class ChatItem extends Component {
  static defaultProps = {
    isSender: false,
    readAt: null,
  }

  static propTypes = {
    isSender: PropTypes.bool,
    text: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    readAt: PropTypes.string,
  }

  render() {
    const { isSender, text, createdAt, readAt } = this.props;
    return (
      <li className={cN(
        'chat-item list relative pt2',
        {
          right: isSender,
          left: !isSender,
          white: isSender,
        },
      )}
      >
        <div className="item-text word-wrap br4 pv2 ph3 tl lh-copy flex flex-column">
          <span className={cN({ white: isSender })}>{text}</span>
          <span className={cN('f6', { white: isSender })}>
            {`Sent ${moment(createdAt).fromNow()} | `}
            {readAt ? `Read ${moment(readAt).fromNow()}` : 'Unread'}
          </span>
        </div>
      </li>
    );
  }
}

export default ChatItem;
