import React from 'react';

import Tab from './Tab';
import './Tabs.css';

export default function Tabs({ tabs, selectedTab, handleTabChange }) {
    return (
        <ul className='tabs mw8 mt3 w-100 center'>
            {tabs.map((tab, i) => {
              return (
                <Tab key={i} label={tab.label} name={tab.name} className={tab.name === selectedTab ? 'selected': ''} handleTabChange={handleTabChange} />
              )
            })}
        </ul>
    )
}